<template>
    <div class="main__block">
        <div class="main__body">
            <h2 class="h2">
                Проверьте данные
            </h2>

            <div class="main__section">
                <h3 class="h3">
                    Персональные данные
                    <a
                        href="#"
                        class="main__goto"
                        @click.prevent="goTo('person')"
                    />
                </h3>

                <div class="info">
                    <div class="info__fieldset">
                        <value
                            type="text"
                            label="ФИО залогодателя"
                            :value="person_name"
                            :inline="true"
                            class="info__field"
                        />
                        <value
                            type="date"
                            label="Дата рождения"
                            :value="item.person.birth_date"
                            :inline="true"
                            class="info__field"
                        />
                    </div>
                    <div class="info__fieldset">
                        <value
                            type="tel"
                            label="Мобильный телефон"
                            :value="item.person.phone"
                            :inline="true"
                            class="info__field"
                        />
                        <value
                            type="tel"
                            label="Домашний телефон"
                            :value="item.person.home_phone"
                            :inline="true"
                            class="info__field"
                        />
                        <value
                            type="email"
                            label="Электронная почта"
                            :value="item.person.email"
                            :inline="true"
                            class="info__field"
                        />
                    </div>
                </div>
            </div>

            <div class="main__section">
                <h3 class="h3">
                    Регистрация
                    <a
                        href="#"
                        class="main__goto"
                        @click.prevent="goTo('address')"
                    />
                </h3>

                <div class="info">
                    <div class="info__fieldset">
                        <value
                            type="text"
                            label="Адрес"
                            :value="item.address.address"
                            :inline="true"
                            class="info__field"
                        />
                    </div>
                </div>
            </div>

            <div class="main__section">
                <h3 class="h3">
                    Документы
                    <a
                        href="#"
                        class="main__goto"
                        @click.prevent="goTo('documents')"
                    />
                </h3>

                <document-list :items="item.documents" />
            </div>

            <div
                v-if="item.rule"
                class="info main__section"
            >
                <div class="info__fieldset">
                    <value
                        type="text"
                        label="Примененное правило"
                        :value="item.rule.name"
                        :inline="true"
                        class="info__field"
                    />
                    <value
                        type="text"
                        label="Гражданство"
                        :value="item.rule.country.name"
                        :inline="true"
                        class="info__field"
                    />
                    <value
                        type="boolean"
                        label="Является резидентом РФ"
                        :value="item.rule.is_resident"
                        :inline="true"
                        class="info__field"
                    />
                </div>
            </div>
        </div>

        <div class="main__foot main__bar">
            <button
                class="button button--white main__cancel"
                @click="prev"
            >
                Вернуться назад
            </button>
            <button
                class="button button--green main__submit"
                @click="next"
            >
                Продолжить заполнение
            </button>
        </div>
    </div>
</template>

<script>
    import value from '@/components/value.vue';
    import documentList from '@/components/document-list.vue';

    export default {
        components: {
            value,
            documentList
        },
        props: {
            item: Object
        },
        computed: {
            person_name() {
                return [ this.item.person.last_name, this.item.person.first_name, this.item.person.patronymic ].join(' ');
            }
        },
        methods: {
            goTo(stage) {
                this.$emit('goto', stage);
            },
            prev() {
                this.$emit('prev');
            },
            next() {
                this.$emit('next');
            }
        }
    };
</script>
