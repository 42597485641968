<template>
    <div class="form">
        <field
            v-model="val.type"
            type="select"
            label="Тип документа"
            :required="true"
            :inline="true"
            :options="opt.type"
            :valid="valid.type"
            :invalid="invalid.type"
            :error="errors.type"
            class="form__field"
        />

        <field
            value=""
            type="document"
            label="Серия/номер"
            :required="true"
            :inline="true"
            class="form__field"
        >
            <template slot="label">
                <label for="serial">Серия</label> /
                <label for="number">номер</label>
            </template>
            <template slot="input">
                <mask-input
                    id="serial"
                    v-model="val.serial"
                    :mask="serialMask"
                    type="text"
                    class="input"
                    :class="{ 'is-valid': valid.serial, 'is-invalid': invalid.serial, 'is-error': errors.serial }"
                />
                <mask-input
                    id="number"
                    v-model="val.number"
                    :mask="numberMask"
                    type="text"
                    class="input"
                    :class="{ 'is-valid': valid.number, 'is-invalid': invalid.number, 'is-error': errors.number }"
                />
            </template>
            <template slot="comment">
                <div
                    v-if="invalid.serial || invalid.number"
                    class="field__tooltip tooltip"
                >
                    {{ invalid.serial || invalid.number }}
                </div>
            </template>
        </field>

        <field
            v-model="val.issue_department"
            type="textarea"
            label="Кем выдан"
            :required="true"
            :inline="true"
            :valid="valid.issue_department"
            :invalid="invalid.issue_department"
            :error="errors.issue_department"
            class="form__field"
        />

        <field
            v-model="val.issue_date"
            type="date"
            label="Дата выдачи"
            :required="true"
            :inline="true"
            :valid="valid.issue_date"
            :invalid="invalid.issue_date"
            :error="errors.issue_date"
            class="form__field"
        />

        <field
            v-model="val.expiry_date"
            type="date"
            label="Действителен до"
            :required="hasExpiryDate"
            :disabled="!hasExpiryDate"
            :inline="true"
            :valid="valid.expiry_date"
            :invalid="invalid.expiry_date"
            :error="errors.expiry_date"
            class="form__field"
        />

        <div
            v-for="message in messages"
            class="form__message"
        >
            {{ message }}
        </div>
    </div>
</template>

<script>
    import _find from 'lodash/find';
    import _map from 'lodash/map';
    import { documents } from '@/services';
    import abstractForm from './abstract-form.vue';
    import maskInput from './inputs/mask-input.vue';

    const fields = [
        { name: 'type', type: 'select' },
        { name: 'serial', type: 'text' },
        { name: 'number', type: 'text' },
        { name: 'issue_department', type: 'textarea' },
        { name: 'issue_date', type: 'date' },
        { name: 'expiry_date', type: 'date' }
    ];

    const checks = {
        type: { required: true },
        serial: { required: true },
        number: { required: true },
        issue_department: { required: true },
        issue_date: { required: true, date: true },
        expiry_date: { required: false, date: true }
    };

    export default {
        components: {
            maskInput
        },
        extends: abstractForm,
        data() {
            return {
                fields,
                checks,
                options: {
                    type: undefined
                }
            };
        },
        computed: {
            currentType() {
                return _find(this.opt.type, { value: this.val.type });
            },
            hasExpiryDate() {
                if (this.currentType) {
                    return this.currentType.has_expiry_date;
                }
                return false;
            },
            serialMask() {
                if (this.currentType) {
                    return this.currentType.serial_mask;
                }
                return '';
            },
            numberMask() {
                if (this.currentType) {
                    return this.currentType.number_mask;
                }
                return '';
            }
        },
        watch: {
            'opt.type': function(value, old) {
                this.checkExpiryDate();
            },
            'val.type': function(value, old) {
                this.checkExpiryDate();
            }
        },
        created() {
            this.$store.dispatch('showLoader', { label: 'Загрузка типов документов' });

            documents.getTypes().then((items) => {
                this.options.type = _map(items, (el) => {
                    return {
                        name: el.name,
                        value: el.code,
                        has_expiry_date: el.has_expiry_date,
                        serial_mask: el.serial_mask,
                        number_mask: el.number_mask
                    };
                });
            }).catch((error) => {
                const err = new Error('Не удалось загрузить типы документов');
                err.parent = error;
                this.$store.dispatch('showError', { err, vm: this });
            }).finally(() => {
                this.$store.dispatch('hideLoader');
            });
        },
        methods: {
            checkExpiryDate() {
                if (this.hasExpiryDate) {
                    this.checks.expiry_date.required = true;
                } else {
                    this.checks.expiry_date.required = false;
                    this.val.expiry_date = '';
                }
            }
        }
    };
</script>
