<template>
    <div class="form">
        <template v-if="isLoaded">
            <field
                type="select"
                value=""
                name="object_guid"
                label="Адрес"
                :inline="true"
                :required="true"
                class="form__field"
            >
                <template slot="input">
                    <search-select
                        v-model="val.object_guid"
                        :options="options.object_guid"
                        :search="searchObjects"
                        :search-delay="300"
                        :query-min-length="3"
                        :filterable="false"
                        placeholder="не выбрано"
                        :class="{ 'is-valid': valid.object_guid, 'is-invalid': invalid.object_guid, 'is-error': errors.object_guid }"
                        @change="changeObject"
                    />
                </template>
            </field>

            <field
                type="house"
                value=""
                label="Дом/квартира"
                :inline="true"
                :required="true"
                class="form__field"
            >
                <template slot="label">
                    <label for="house">Дом</label> /
                    <label for="room">квартира</label>
                </template>
                <template slot="input">
                    <select
                        id="house"
                        v-model.lazy="val.house_guid"
                        :disabled="!options.house_guid || !options.house_guid.length"
                        class="select"
                        :class="{ 'is-valid': valid.house_guid, 'is-invalid': invalid.house_guid, 'is-error': errors.house_guid }"
                        @change="changeHouse"
                    >
                        <option value="">
                            не выбрано
                        </option>
                        <option
                            v-for="option in options.house_guid"
                            :value="option.value"
                        >
                            {{ option.name }}
                        </option>
                    </select>
                    <select
                        id="room"
                        v-model.lazy="val.room_guid"
                        :disabled="!options.room_guid || !options.room_guid.length"
                        class="select"
                        :class="{ 'is-valid': valid.room_guid, 'is-invalid': invalid.room_guid, 'is-error': errors.room_guid }"
                        @change="changeRoom"
                    >
                        <option value="">
                            не выбрано
                        </option>
                        <option
                            v-for="option in options.room_guid"
                            :value="option.value"
                        >
                            {{ option.name }}
                        </option>
                    </select>
                </template>
            </field>
        </template>
    </div>
</template>

<script>
    import _map from 'lodash/map';
    import { fias } from '@/services';
    import abstractForm from './abstract-form.vue';
    import searchSelect from './inputs/search-select.vue';

    const fields = [
        { name: 'object_guid', type: 'select' },
        { name: 'house_guid', type: 'select' },
        { name: 'room_guid', type: 'select' }
    ];

    const checks = {
        object_guid: { required: true },
        house_guid: { required: true }
    };

    export default {
        components: {
            searchSelect
        },
        extends: abstractForm,
        data() {
            return {
                fields,
                checks,
                options: {
                    object_guid: undefined,
                    house_guid: undefined,
                    room_guid: undefined
                },
                isLoaded: false
            };
        },
        created() {
            if (this.val.object_guid) {
                this.$store.dispatch('showLoader', { label: 'Загрузка начальных значений' });

                fias.getInitial(this.serialize()).then((data) => {
                    this.fillObjects(data.objects);
                    this.fillHouses(data.houses);
                    this.fillRooms(data.rooms);

                    this.isLoaded = true;
                }).catch((error) => {
                    const err = new Error('Не удалось загрузить начальные значения');
                    err.parent = error;
                    this.$store.dispatch('showError', { err, vm: this });
                }).finally(() => {
                    this.$store.dispatch('hideLoader');
                });
            } else {
                this.fillObjects([]);
                this.fillHouses([]);
                this.fillRooms([]);

                this.isLoaded = true;
            }
        },
        methods: {
            searchObjects(query) {
                if (query === '') {
                    return Promise.resolve([]);
                }

                return fias.searchObjects({ query: query }).then((items) => {
                    this.fillObjects(items);
                    return null;
                });
            },
            changeObject() {
                this.val.house_guid = '';
                this.val.room_guid = '';

                this.fillHouses([]);
                this.fillRooms([]);

                if (this.val.object_guid) {
                    this.$store.dispatch('showLoader', { label: 'Загрузка домов' });

                    fias.getHouses({ object_guid: this.val.object_guid }).then((items) => {
                        this.fillHouses(items);
                        this.fillRooms([]);
                    }).catch((error) => {
                        const err = new Error('Не удалось загрузить дома');
                        err.parent = error;
                        this.$store.dispatch('showError', { err, vm: this });
                    }).finally(() => {
                        this.$store.dispatch('hideLoader');
                    });
                }
            },
            changeHouse() {
                this.val.room_guid = '';

                this.fillRooms([]);

                if (this.val.house_guid) {
                    this.$store.dispatch('showLoader', { label: 'Загрузка помещений' });

                    fias.getRooms({ house_guid: this.val.house_guid }).then((items) => {
                        this.fillRooms(items);
                    }).catch((error) => {
                        const err = new Error('Не удалось загрузить помещения');
                        err.parent = error;
                        this.$store.dispatch('showError', { err, vm: this });
                    }).finally(() => {
                        this.$store.dispatch('hideLoader');
                    });
                }
            },
            changeRoom() {
                    
            },
            fillObjects(items) {
                this.options.object_guid = _map(items, (el) => {
                    return {
                        name: el.full_name,
                        value: el.object_guid
                    };
                });
            },
            fillHouses(items) {
                this.options.house_guid = _map(items, (el) => {
                    return {
                        name: el.house_name,
                        value: el.house_guid
                    };
                });
            },
            fillRooms(items) {
                this.options.room_guid = _map(items, (el) => {
                    return {
                        name: el.room_name,
                        value: el.room_guid
                    };
                });
            }
        }
    };
</script>
