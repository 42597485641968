<template>
    <v-select
        :value="val"
        :options="opts"
        :filterable="filterable"
        label="name"
        class="autocomplete"
        @input="change"
        @search="onSearch"
    >
        <span slot="no-options">{{ emptyLabel }}</span>
    </v-select>
</template>

<script>
    import vSelect from 'vue-select';

    export default {
        components: {
            vSelect
        },
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: {
                type: String,
                default: ''
            },
            options: {
                type: Array,
                default() { return []; }
            },
            search: {
                type: Function,
                default: undefined
            },
            filterable: {
                type: Boolean,
                default: true
            },
            searchDelay: {
                type: Number,
                default: 0
            },
            queryMinLength: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                opts: this.options,
                val: this.encode(this.value, this.options),
                timer: -1,
                query: ''
            };
        },
        computed: {
            queryIsShort() {
                return this.query.length < this.queryMinLength;
            },
            emptyLabel() {
                return this.queryIsShort ? `Введите не менее ${ this.queryMinLength } символов` : 'Ничего не найдено';
            }
        },
        watch: {
            value(value) {
                this.val = this.encode(value, this.opts);
            },
            options(value) {
                this.opts = value;
            }
        },
        methods: {
            onSearch(query, loading) {
                if (!this.search) {
                    return;
                }

                this.query = query;

                if (this.timer !== -1) {
                    clearTimeout(this.timer);
                    loading(false);
                    this.timer = -1;
                }

                if (!this.queryIsShort) {
                    loading(true);

                    this.timer = setTimeout(() => {
                        loading(true);
                        this.search(query).then((items) => {
                            if (items) {
                                this.opts = items;
                            }
                        }).catch((error) => {
                            const err = new Error('Не удалось получить варианты');
                            err.parent = error;
                            this.$store.dispatch('showError', { err, vm: this });
                        }).finally(() => {
                            loading(false);
                        });
                    }, this.searchDelay);
                } else {
                    this.opts = [];
                }
            },
            change(value) {
                let newValue = this.decode(value);
                if (newValue !== this.value) {
                    this.$emit('change', newValue);
                }
            },
            encode(value, options) {
                for (let opt of options) {
                    if (opt.value === value) {
                        return opt;
                    }
                }
                return null;
            },
            decode(value) {
                return (value && value.value) || '';
            }
        }
    };
</script>
