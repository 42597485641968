<template>
    <div class="main__block">
        <div class="main__body">
            <h2 class="h2">
                Укажите адрес регистрации
            </h2>
            <address-form
                ref="form"
                :values="values"
                @change="checkValid"
            />
        </div>

        <div class="main__foot main__bar">
            <button
                class="button button--white main__cancel"
                @click="prev"
            >
                Вернуться назад
            </button>
            <button
                class="button button--green main__submit"
                :disabled="!isValid"
                @click="next"
            >
                Продолжить заполнение
            </button>
        </div>
    </div>
</template>

<script>
    import _orderBy from 'lodash/orderBy';
    import { fias } from '@/services';
    import addressForm from '@/components/address-form.vue';

    export default {
        components: {
            addressForm
        },
        props: {
            item: Object
        },
        data() {
            return {
                isValid: false
            };
        },
        computed: {
            values() {
                let values = {
                    object_guid: '',
                    house_guid: '',
                    room_guid: ''
                };
                if (this.item.address) {
                    if (this.item.address.objects && this.item.address.objects.length) {
                        let object = _orderBy(this.item.address.objects, ['object_level'], ['desc'])[0];
                        if (object) {
                            values.object_guid = object.object_guid;
                        }
                    }
                    if (this.item.address.house) {
                        values.house_guid = this.item.address.house.house_guid;
                    }
                    if (this.item.address.room) {
                        values.room_guid = this.item.address.room.room_guid;
                    }
                }
                return values;
            }
        },
        mounted() {
            this.checkValid();
        },
        methods: {
            serialize() {
                return this.$refs.form.serialize();
            },
            checkValid() {
                this.isValid = this.$refs.form.validate();
            },
            prev() {
                this.$store.dispatch('showLoader', { label: 'Детализация адреса' });

                fias.getDetail(this.serialize()).then((data) => {
                    this.$emit('prev', data);
                }).catch((error) => {
                    const err = new Error('Не удалось детализировать адрес');
                    err.parent = error;
                    this.$store.dispatch('showError', { err, vm: this });
                }).finally(() => {
                    this.$store.dispatch('hideLoader');
                });
            },
            next() {
                this.$store.dispatch('showLoader', { label: 'Детализация адреса' });

                fias.getDetail(this.serialize()).then((data) => {
                    this.$emit('next', data);
                }).catch((error) => {
                    const err = new Error('Не удалось детализировать адреса');
                    err.parent = error;
                    this.$store.dispatch('showError', { err, vm: this });
                }).finally(() => {
                    this.$store.dispatch('hideLoader');
                });
            }
        }
    };
</script>
