<template>
    <div class="main__block">
        <div class="main__body">
            <h2 class="h2">
                Укажите персональные данные
            </h2>
            <person-form
                ref="form"
                :values="item.person"
                class="main__section"
                @change="checkValid"
            />
            <state-form
                :values="item.state"
                class="main__section"
            />
        </div>

        <div class="main__foot main__bar">
            <button
                class="button button--white main__cancel"
                @click="prev"
            >
                Вернуться назад
            </button>
            <button
                class="button button--green main__submit"
                :disabled="!isValid"
                @click="next"
            >
                Продолжить заполнение
            </button>
        </div>
    </div>
</template>

<script>
    import personForm from '@/components/person-form.vue';
    import stateForm from '@/components/state-form.vue';

    export default {
        components: {
            personForm,
            stateForm
        },
        props: {
            item: Object
        },
        data() {
            return {
                isValid: false
            };
        },
        mounted() {
            this.checkValid();
        },
        methods: {
            checkValid() {
                this.isValid = this.$refs.form.validate();
            },
            serialize() {
                return this.$refs.form.serialize();
            },
            prev() {
                this.$emit('prev', this.serialize());
            },
            next() {
                this.$emit('next', this.serialize());
            }
        }
    };
</script>
