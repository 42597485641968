<script>
    import abstractForm from './abstract-form.vue';

    const fields = [
        { name: 'last_name', type: 'text', label: 'Фамилия', required: true },
        { name: 'first_name', type: 'text', label: 'Имя', required: true },
        { name: 'patronymic', type: 'text', label: 'Отчество', required: true },
        { name: 'birth_date', type: 'date', label: 'Дата рождения', required: true },
        { name: 'phone', type: 'tel', label: 'Мобильный телефон', required: true },
        { name: 'home_phone', type: 'tel', label: 'Домашний телефон' },
        { name: 'email', type: 'email', label: 'Email' }
    ];

    const fieldsets = [
        { names: ['last_name', 'first_name', 'patronymic', 'birth_date'] },
        { names: ['phone', 'home_phone', 'email'] }
    ];

    const checks = {
        last_name: { required: true, name: true },
        first_name: { required: true, name: true },
        patronymic: { required: true, name: true },
        birth_date: { required: true, date: true },
        phone: { required: true, tel: true },
        home_phone: { tel: true },
        email: { email: true }
    };

    export default {
        extends: abstractForm,
        data() {
            return {
                fields,
                fieldsets,
                checks,
                inline: true
            };
        }
    };
</script>
