<template>
    <div class="entity-list">
        <div
            v-if="items.length"
            class="entity-list__list"
        >
            <div
                v-for="(item, idx) in items"
                class="entity-list__item entity-item"
            >
                <div class="entity-item__head entity-item__header">
                    {{ getType(item.type) }}
                </div>

                <div class="entity-item__body entity-item__main">
                    <div class="entity-item__section">
                        <div
                            v-if="item.serial"
                            class="entity-item__attr"
                        >
                            <span class="entity-item__attr-label">
                                Серия:
                            </span>
                            <span class="entity-item__attr-value">
                                {{ item.serial }}
                            </span>
                        </div>
                        <div
                            v-if="item.number"
                            class="entity-item__attr"
                        >
                            <span class="entity-item__attr-label">
                                Номер:
                            </span>
                            <span class="entity-item__attr-value">
                                {{ item.number }}
                            </span>
                        </div>
                    </div>
                    <div class="entity-item__section">
                        <div
                            v-if="item.issue_department"
                            class="entity-item__attr"
                        >
                            <span class="entity-item__attr-label">
                                Выдан:
                            </span>
                            <span class="entity-item__attr-value">
                                {{ item.issue_department }}
                            </span>
                        </div>
                        <div
                            v-if="item.issue_date"
                            class="entity-item__attr"
                        >
                            <span class="entity-item__attr-label">
                                Дата выдачи:
                            </span>
                            <span class="entity-item__attr-value">
                                {{ item.issue_date | date('DD.MM.YYYY') }}
                            </span>
                        </div>
                        <div
                            v-if="item.expiry_date"
                            class="entity-item__attr"
                        >
                            <span class="entity-item__attr-label">
                                Дата истечения:
                            </span>
                            <span class="entity-item__attr-value">
                                {{ item.expiry_date | date('DD.MM.YYYY') }}
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    v-if="item.controllable && controls.length"
                    class="entity-item__foot entity-item__panel"
                >
                    <div
                        v-for="control in controls"
                        class="entity-item__panel-item"
                    >
                        <a
                            href="#"
                            class="a"
                            :class="control.color ? 'a--' + control.color : ''"
                            @click.prevent="callControl(control.event, idx)"
                        >
                            {{ control.label }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-else
            class="entity-list__empty"
        >
            <slot name="empty">
                Список пуст
            </slot>
        </div>
    </div>
</template>

<script>
    import _find from 'lodash/find';
    import { documents } from '@/services';

    export default {
        props: {
            items: {
                type: Array,
                default() { return []; }
            },
            controls: {
                type: Array,
                default() { return []; }
            }
        },
        data() {
            return {
                types: []
            };
        },
        created() {
            this.$store.dispatch('showLoader', { label: 'Загрузка типов документов' });

            documents.getTypes().then((items) => {
                this.types = items;
            }).catch((error) => {
                const err = new Error('Не удалось загрузить типы документов');
                err.parent = error;
                this.$store.dispatch('showError', { err, vm: this });
            }).finally(() => {
                this.$store.dispatch('hideLoader');
            });
        },
        methods: {
            getType(code) {
                let item = _find(this.types, { code });
                return item ? item.name : '';
            },
            callControl(event, idx) {
                this.$emit('control', event, idx);
                this.$emit('control:' + event, idx);
            }
        }
    };
</script>
