<template>
    <div class="main__block">
        <div class="main__body form">
            <field
                type="tel"
                :value="values.person.phone"
                label="Мобильный телефон"
                :inline="true"
                :disabled="true"
                class="form__field"
            />

            <div
                v-if="isVerified"
                class="form__field field field--type_text field--inline"
            >
                <div class="field__label">
                    Верификация
                </div>
                <div class="field__input">
                    <button
                        :disabled="true"
                        class="inpton inpton--green"
                    >
                        Пройдена
                    </button>
                </div>
            </div>
            <div
                v-else-if="isRequested"
                class="form__field field field--type_verify field--inline"
            >
                <div class="field__label">
                    Верификация
                </div>
                <div class="field__input">
                    <input
                        v-model="code"
                        type="text"
                        class="input"
                        :class="{ 'is-valid': valid.code, 'is-invalid': invalid.code, 'is-error': errors.code }"
                    >
                    <button
                        :disabled="!isValid"
                        class="inpton inpton--green"
                        @click="confirm"
                    >
                        Верифицировать
                    </button>
                    <button
                        class="inpton inpton--yellow"
                        @click="request"
                    >
                        Отправить снова
                    </button>
                </div>
            </div>
            <div
                v-else
                class="form__field field field--type_text field--inline"
            >
                <div class="field__label">
                    Верификация
                </div>
                <div class="field__input">
                    <button
                        class="inpton inpton--purple"
                        @click="request"
                    >
                        Отправить код
                    </button>
                </div>
            </div>

            <div
                v-for="message in messages"
                class="form__message form__message--error"
            >
                {{ message }}
            </div>
        </div>

        <div class="main__foot main__bar">
            <button
                class="button button--white main__cancel"
                @click="prev"
            >
                Вернуться назад
            </button>
            <button
                class="button button--green main__submit"
                @click="next"
            >
                Продолжить заполнение
            </button>
        </div>

        <modal
            :is-shown="!!result"
            @hide="hide"
        >
            <h3
                slot="header"
                class="modal__header h2"
            >
                Клиент верифицирован
            </h3>
            <div
                slot="content"
                class="modal__content text"
            >
                <p>Клиент был успешно верифицирован.</p>
            </div>
            <div
                slot="footer"
                class="modal__panel"
            >
                <button
                    class="modal__prev button button--white"
                    @click="hide"
                >
                    Назад
                </button>
                <button
                    class="modal__next button button--green"
                    @click="next"
                >
                    Далее
                </button>
            </div>
        </modal>
    </div>
</template>

<script>
    import { clients } from '@/services';
    import field from '@/components/field.vue';
    import modal from '@/components/modal.vue';

    export default {
        components: {
            field,
            modal
        },
        props: {
            values: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                isVerified: !!this.values.state.is_verified,
                code: '',
                isRequested: false,
                result: undefined,
                errors: {},
                messages: []
            };
        },
        computed: {
            isValid() {
                return this.isRequested && this.code !== '';
            },
            valid() {
                if (this.isRequested) {
                    return {
                        code: this.code !== ''
                    };
                }
                return {};
            },
            invalid() {
                if (this.isRequested) {
                    return {
                        code: this.code === ''
                    };
                }
                return {};
            }
        },
        methods: {
            request() {
                this.$store.dispatch('showLoader', { label: 'Запрос кода' });

                this.errors = {};
                this.messages = [];

                clients.requestVerification(this.values.id, {}).then((data) => {
                    this.isRequested = true;
                    this.code = data.code;
                }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.detail) {
                        this.messages = [ error.response.data.detail ];
                    } else {
                        const err = new Error('Не удалось запросить верификацию');
                        err.parent = error;
                        this.$store.dispatch('showError', { err, vm: this });
                    }
                }).finally(() => {
                    this.$store.dispatch('hideLoader');
                });
            },
            confirm() {
                this.$store.dispatch('showLoader', { label: 'Подтверждение' });

                this.errors = {};
                this.messages = [];

                clients.confirmVerification(this.values.id, {
                    code: this.code
                }).then((data) => {
                    this.isVerified = true;
                    this.result = data;
                }).catch((error) => {
                    if (error.response && error.response.data) {
                        this.errors = error.response.data;
                        if (error.response.data.detail) {
                            this.messages = [ error.response.data.detail ];
                        }
                    } else {
                        const err = new Error('Не удалось подтвердить верификацию');
                        err.parent = error;
                        this.$store.dispatch('showError', { err, vm: this });
                    }
                }).finally(() => {
                    this.$store.dispatch('hideLoader');
                });
            },
            hide() {
                this.result = undefined;
            },
            prev() {
                this.$emit('prev', this.isVerified);
            },
            next() {
                this.$emit('next', this.isVerified);
            }
        }
    };
</script>
