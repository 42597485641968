<script>
    import abstractForm from './abstract-form.vue';

    const fields = [
        { name: 'is_verified', type: 'boolean', label: 'Телефон верифицирован', disabled: true },
        { name: 'is_registered', type: 'boolean', label: 'Зарегистрирован на сайте', disabled: true },
        { name: 'is_subscribed', type: 'boolean', label: 'Подписан на telegram-бота', disabled: true }
    ];

    export default {
        extends: abstractForm,
        data() {
            return {
                fields,
                inline: true
            };
        }
    };
</script>
