<template>
    <div class="main__block">
        <person
            v-if="isStage('person')"
            :item="item"
            @next="nextPerson"
            @prev="prevPerson"
        />
        <addr
            v-if="isStage('address')"
            :item="item"
            @next="nextAddress"
            @prev="prevAddress"
        />
        <documents
            v-if="isStage('documents')"
            :item="item"
            @next="nextDocuments"
            @prev="prevDocuments"
        />
        <confirm
            v-if="isStage('confirm')"
            :item="item"
            @next="nextConfirm"
            @prev="prevConfirm"
            @goto="goTo"
        />
    </div>
</template>

<script>
    import person from './person.vue';
    import addr from './address.vue';
    import documents from './documents.vue';
    import confirm from './confirm.vue';

    export default {
        components: {
            person,
            addr,
            documents,
            confirm
        },
        props: {
            values: {
                type: Object,
                default() { return {
                    person: undefined,
                    state: undefined,
                    address: undefined,
                    documents: [],
                    rule: undefined
                }; }
            }
        },
        data() {
            return {
                stage: 'person',
                person: this.values.person,
                state: this.values.state,
                address: this.values.address,
                documents: this.values.documents,
                rule: this.values.rule
            };
        },
        computed: {
            item() {
                return {
                    person: this.person,
                    state: this.state,
                    address: this.address,
                    documents: this.documents,
                    rule: this.rule
                };
            }
        },
        methods: {
            isStage(stage) {
                return this.stage === stage;
            },
            goTo(stage) {
                this.stage = stage;
            },
            serialize() {
                return this.item;
            },
            prev() {
                this.$emit('prev', this.serialize());
            },
            next() {
                this.$emit('next', this.serialize());
            },
            prevPerson(params) {
                this.person = params;
                this.prev();
            },
            nextPerson(params) {
                this.person = params;
                this.goTo('address');
            },
            prevAddress(params) {
                this.address = params;
                this.goTo('person');
            },
            nextAddress(params) {
                this.address = params;
                this.goTo('documents');
            },
            prevDocuments(items, rule) {
                this.documents = items;
                this.rule = rule;
                this.goTo('address');
            },
            nextDocuments(items, rule) {
                this.documents = items;
                this.rule = rule;
                this.goTo('confirm');
            },
            prevConfirm() {
                this.goTo('documents');
            },
            nextConfirm() {
                this.next();
            }
        }
    };
</script>
