<template>
    <div class="main__block">
        <template v-if="isStage('main')">
            <div class="main__body">
                <h2 class="h2">
                    Укажите список документов
                </h2>
                <document-list
                    :items="items"
                    :controls="controls"
                    @control:update="showItem"
                    @control:delete="deleteItem"
                />
            </div>

            <div class="main__foot main__bar">
                <button
                    class="main__prev button button--white"
                    @click="prev"
                >
                    Вернуться назад
                </button>
                <button
                    class="main__this button button--yellow"
                    @click="showItem(-1)"
                >
                    Добавить документ
                </button>
                <button
                    :disabled="!isValid"
                    class="main__next button button--green"
                    @click="check"
                >
                    Продолжить заполнение
                </button>
            </div>
        </template>
        <template v-else-if="isStage('item')">
            <div class="main__body">
                <h2 class="h2">
                    {{ currentItem ? 'Измените документ' : 'Добавьте документ' }}
                </h2>
                <document-form
                    ref="item"
                    :values="currentItem"
                    @change="checkItemValid"
                />
            </div>

            <div class="main__foot main__bar">
                <button
                    class="main__cancel button button--white"
                    @click="showMain"
                >
                    Отменить
                </button>
                <button
                    :disabled="!itemIsValid"
                    class="main__submit button button--green"
                    @click="submitItem"
                >
                    {{ currentItem ? 'Внести изменения' : 'Добавить' }}
                </button>
            </div>
        </template>

        <modal
            :is-shown="!!result"
            @hide="hide"
        >
            <template v-if="result">
                <template v-if="result.is_approved">
                    <h3
                        slot="header"
                        class="modal__header h2"
                    >
                        Список документов прошел проверку
                    </h3>
                    <div
                        slot="content"
                        class="modal__content text"
                    >
                        <h4>Гражданство: {{ result.country.name }}</h4>
                        <h4>Является резидентом РФ: {{ result.is_resident ? 'Да' : 'Нет' }}</h4>
                    </div>
                    <div
                        slot="footer"
                        class="modal__panel"
                    >
                        <button
                            class="modal__prev button button--white"
                            @click="hide"
                        >
                            Назад
                        </button>
                        <button
                            class="modal__next button button--green"
                            @click="next"
                        >
                            Далее
                        </button>
                    </div>
                </template>
                <template v-else>
                    <h3
                        slot="header"
                        class="modal__header h2"
                    >
                        Список документов не прошел проверку
                    </h3>
                    <div
                        slot="content"
                        class="modal__content text"
                    >
                        <p v-if="result.detail">
                            {{ result.detail }}
                        </p>
                    </div>
                    <div
                        slot="footer"
                        class="modal__panel"
                    >
                        <button
                            class="modal__submit button button--red"
                            @click="hide"
                        >
                            Закрыть
                        </button>
                    </div>
                </template>
            </template>
        </modal>
    </div>
</template>

<script>
    import { documents } from '@/services';
    import documentList from '@/components/document-list.vue';
    import documentForm from '@/components/document-form.vue';
    import modal from '@/components/modal.vue';

    export default {
        components: {
            documentList,
            documentForm,
            modal
        },
        props: {
            item: Object
        },
        data() {
            return {
                stage: 'main',
                items: this.item.documents,
                result: undefined,
                messages: [],
                itemIsValid: false,
                idx: -1,
                controls: [
                    { event: 'update', label: 'Редактировать', color: 'yellow' },
                    { event: 'delete', label: 'Удалить', color: 'red' }
                ]
            };
        },
        computed: {
            isValid() {
                return !!this.items.length;
            },
            currentItem() {
                return (this.idx !== -1) ? this.items[ this.idx ] : undefined;
            }
        },
        methods: {
            isStage(stage) {
                return this.stage === stage;
            },
            check() {
                this.$store.dispatch('showLoader', { label: 'Проверка списка документов' });
                this.result = undefined;

                documents.checkDocuments({
                    items: this.items
                }).then((data) => {
                    this.result = data;
                }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.detail) {
                        this.result = {
                            detail: error.response.data.detail
                        };
                    } else {
                        const err = new Error('Не удалось проверить список документов');
                        err.parent = error;
                        this.$store.dispatch('showError', { err, vm: this });
                    }
                }).finally(() => {
                    this.$store.dispatch('hideLoader');
                });
            },
            showMain() {
                this.stage = 'main';
                this.idx = -1;
            },
            showItem(idx) {
                this.idx = idx;
                this.stage = 'item';

                this.$nextTick(() => {
                    this.checkItemValid();
                });
            },
            submitItem() {
                let data = this.$refs.item.serialize();
                data.controllable = true;

                if (this.idx !== -1) {
                    this.items[ this.idx ] = data;
                } else {
                    this.items.push(data);
                }

                this.showMain();
            },
            deleteItem(idx) {
                this.items.splice(idx, 1);
                this.showMain();
            },
            checkItemValid() {
                this.itemIsValid = this.$refs.item.validate();
            },
            hide() {
                this.result = undefined;
            },
            prev() {
                this.$emit('prev', this.items);
            },
            next() {
                this.$emit('next', this.items, this.rule);
            }
        }
    };
</script>
